import { COLORS, ChipV2, Text } from "@asayinc/component-library";
import { Stack, SxProps, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { Avatar } from "src/components/atoms/Avatar";
import {
  getSubmitByDate,
  getSubmitByTime,
  getTitle,
  isPassedCutoffDate,
} from "src/pages/Communications/utils";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { EventHeaderBanner } from "./EventHeaderBanner";
import {
  getAttendMeetingBannerText,
  getContestingParty,
  isContestedMeeting,
} from "./utils";

interface IEventHeaderProps {
  sx?: SxProps;
  hideBanner?: boolean;
}

export function EventHeader({ sx, hideBanner = false }: IEventHeaderProps) {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const theme = useTheme();
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const isLoading = isCommunicationLoading || isBrokerLoading;

  const handleLegalProxyClick = () => {
    if (communication?.meetingAttendance?.document) {
      window.open(communication.meetingAttendance.document, "_blank");
    }
  };

  return (
    <Stack
      data-testid="event-header"
      alignItems="center"
      sx={{ width: "100%", backgroundColor: "#FFFFFF", ...sx }}
    >
      <Avatar
        name={communication?.security.ticker}
        color={isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main}
        size={48}
        sx={{ mb: 3 }}
      />
      <Text
        sx={{ mb: 3 }}
        variant="subtitle2"
        loadingProps={{ sx: { width: "120px" } }}
        data-testid="issuer-name"
      >
        {isLoading ? undefined : communication?.security.issuerName}
      </Text>
      <Text
        data-testid="communication-title"
        variant="h5"
        loadingProps={{ sx: { width: "280px" } }}
      >
        {isLoading || !communication ? undefined : getTitle(communication)}
      </Text>
      {communication && isContestedMeeting(communication) && (
        <ChipV2
          label={
            <Text
              variant="body2"
              sx={{ whiteSpace: "normal", textAlign: "center" }}
            >
              Contested by dissident party -{" "}
              <b>{getContestingParty(communication)}</b>
            </Text>
          }
          size="small"
          sx={{
            mt: 3,
            "& .MuiChip-deleteIcon": {
              color: theme.palette.l1.main,
            },
          }}
          variant="primary"
          tooltip={`${getContestingParty(communication)} is a shareholder of ${
            communication.security.issuerName
          } and is contesting management's vote recommendations on this year's ballot.`}
          disabled={isLoading}
        />
      )}
      {!hideBanner && communication && isPassedCutoffDate(communication) && (
        <EventHeaderBanner
          bannerText={
            isLoading
              ? undefined
              : `This ballot closed on ${getSubmitByDate(
                  communication
                )} at ${getSubmitByTime(
                  communication
                )} when the vote deadline passed.`
          }
          iconName="TimeFill"
          iconColor={COLORS.WHITE}
          testId="ballot-closed-banner"
        />
      )}
      {!hideBanner && communication?.meetingAttendance && (
        <EventHeaderBanner
          iconName="HowToRegFill"
          iconColor={COLORS.BRIGHT_GREEN}
          bannerText={
            isLoading ? undefined : getAttendMeetingBannerText(communication)
          }
          showLegalProxy={
            communication?.meetingAttendance.legalProxy &&
            communication?.meetingAttendance.format === "in_person" &&
            !!communication?.meetingAttendance.document
          }
          onLegalProxyClick={handleLegalProxyClick}
          testId="legal-proxy-banner"
        />
      )}
    </Stack>
  );
}

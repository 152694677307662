import { COLORS, LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";

import { Communication } from "src/types";
import { ResourceRow } from "../ResourceRow";
import { isContestedMeeting } from "src/pages/Communication/components/EventHeader/utils";

interface IShareholderResourcesProps {
  communication: Communication | undefined;
  isLoading: boolean;
  isProxyCommunication: boolean | undefined;
}

export function ShareholderResources({
  communication,
  isLoading,
  isProxyCommunication,
}: IShareholderResourcesProps) {
  const theme = useTheme();
  const isContested = communication ? isContestedMeeting(communication) : false;

  const managementMaterials =
    communication?.documents.filter((d) => !d.contestingPartyUpload) || [];
  const dissidentMaterials =
    communication?.documents.filter((d) => d.contestingPartyUpload) || [];

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        mb: 8,
        p: 6,
        [theme.breakpoints.down("sm")]: {
          mb: isProxyCommunication ? 4 : 0,
        },
      }}
      data-testid="sidebar-card"
    >
      <Text
        variant="subtitle2"
        emphasis="high"
        sx={{ mb: 3, color: theme.palette.l4.main }}
        loadingProps={{ sx: { width: "120px" } }}
      >
        {isLoading ? undefined : "Shareholder resources"}
      </Text>
      {isLoading ? (
        <Box>
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Text variant="body2" sx={{ mb: 4 }}>
          {`Official materials provided by the company to help inform your ${
            isProxyCommunication ? "vote" : "election"
          }`}
        </Text>
      )}
      {isLoading ? (
        <Box>
          <Box sx={{ display: "flex", my: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
        </Box>
      ) : (
        <>
          {isContested && (
            <Text variant="subtitle2" emphasis="high" sx={{ mb: 2, mt: 6 }}>
              Management materials
            </Text>
          )}
          {managementMaterials.map((attachment, index) => {
            return (
              <ResourceRow
                key={`management-resource-${index}`}
                attachment={attachment}
              />
            );
          })}
          {isContested && dissidentMaterials.length > 0 && (
            <Text
              variant="subtitle2"
              emphasis="high"
              sx={{
                my: 2,
                pt: 4,
                borderTop: `1px solid ${COLORS.P_OUTLINE}`,
              }}
            >
              Dissident materials
            </Text>
          )}
          {isContested &&
            dissidentMaterials.map((attachment, index) => {
              return (
                <ResourceRow
                  key={`management-resource-${index}`}
                  attachment={attachment}
                />
              );
            })}
        </>
      )}
    </Box>
  );
}

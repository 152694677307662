import { COLORS, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { formatDate } from "src/utils/formatDate";

interface IProps {
  issuerName?: string;
  recordDate?: string;
  brokerName?: string;
  isActive: boolean;
}

const Disclaimer = ({
  issuerName,
  recordDate,
  brokerName,
  isActive,
}: IProps) => {
  // data needed to show disclaimer
  const isDisclaimerDataReady = issuerName && recordDate && brokerName;

  // dont display if data is unavailable
  if (!isDisclaimerDataReady || !isActive) {
    return null;
  }

  return (
    <Box data-testid="vote-landing-disclaimer">
      <Text variant="body2" emphasis="medium">
        You&apos;re receiving this ballot because you owned shares of{" "}
        {issuerName} on {formatDate({ date: recordDate })} on {brokerName}.{" "}
        <a
          href="https://saytechnologies.com/disclosures/"
          target="_blank"
          rel="noreferrer"
          style={{ color: COLORS.P_MED_EMPHASIS }}
        >
          View full disclaimer here
        </a>
      </Text>
    </Box>
  );
};

export default Disclaimer;

import { Text } from "@asayinc/component-library";
import { useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { ElectionChoice as ElectionChoiceType } from "src/types/Communication";
import { CorporateActionCommunication } from "src/types";
import { getLabel } from "./utils";
import { ChoiceLabel } from "../ChoiceLabel";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

export type FormErrorInfo = {
  inputName: string;
  errorMessage: string;
  errorType: string;
  value: string;
};

interface IElectionChoiceProps {
  choice: ElectionChoiceType;
  communication: CorporateActionCommunication;
  hasErrors: boolean;
  onClick: () => void;
}

export function ElectionChoice({
  choice,
  communication,
  hasErrors,
  onClick,
}: IElectionChoiceProps) {
  const { brokerId } = useParams() as { brokerId: string };
  const form = useFormContext();
  const theme = useTheme();
  const { watch } = form;
  const currentChoice = watch("choice");

  const { corporateActionEvent, positions } = communication;
  const { type } = corporateActionEvent;
  const sharesOwned = positions.reduce((acc, position) => {
    return Number(position.sharesOwned) + acc;
  }, 0);
  const isSelected = choice === currentChoice;
  const selectedColor = theme.palette.l4.main;
  const isDetailsInline =
    positions.length === 1 &&
    choice !== "no" &&
    (type === "buyback" ||
      type === "buyback_auction" ||
      type === "exchange_offer" ||
      type === "offer_to_purchase" ||
      type === "offer_to_purchase_auction" ||
      type === "rights_offer" ||
      type === "rights_offer_oversubscription");
  const isExchange =
    type === "exchange_offer" ||
    type === "rights_offer" ||
    type === "rights_offer_oversubscription";
  const isRights =
    type === "rights_offer" || type === "rights_offer_oversubscription";

  const handleOnError = (errorInfo: FormErrorInfo) => {
    track({
      name: TrackEventType.CommunicationDetailsFormError,
      communicationId: communication.id,
      customerId: communication.customer.id,
      brokerId: brokerId,
      ...errorInfo,
    });
  };

  return isDetailsInline ? (
    <ChoiceLabel
      hasErrors={hasErrors}
      inputName={`choiceDetails.0.${
        type === `rights_offer` || type === "rights_offer_oversubscription"
          ? "rightsElected"
          : "sharesElected"
      }`}
      isOversubscribed={type === "rights_offer_oversubscription"}
      isRights={isRights}
      isSelected={isSelected}
      onClick={onClick}
      onError={handleOnError}
      preInputText={`Yes, I would like to ${isExchange ? "exchange" : "sell"}`}
      postInputText={`${isRights ? "rights" : "shares"}${
        type === "rights_offer_oversubscription" ? " and" : ""
      }`}
      sharesOwned={sharesOwned}
    />
  ) : (
    <Text
      variant="body2"
      sx={{ color: isSelected ? selectedColor : undefined }}
    >
      {getLabel(choice, communication)}
    </Text>
  );
}

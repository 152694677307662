import { Box, Stack, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";

import { CommunicationRow } from "./components/CommunicationRow";
import { CommunicationsHeader } from "./components/CommunicationsHeader";
import { useCommunicationsQuery } from "src/store/warrenG/communication";
import { VoteSuccessDialog } from "./components/VoteSuccessDialog";
import { LoadingCommunicationRow } from "./components/LoadingCommunicationRow";
import { VoteWithdrawnDialog } from "./components/VoteWithdrawnDialog";
import { GeneralEmptyState } from "src/components/molecules/GeneralEmptyState";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

type CommunicationsTabs = "open" | "past";

export function Communications() {
  const { brokerId, customerId } = useParams() as {
    brokerId: string;
    customerId: string;
  };
  const [activeTab, setActiveTab] = useState<CommunicationsTabs>("open");
  const { isLoading, data: communications } = useCommunicationsQuery({
    brokerId,
    customerId,
    isOpen: activeTab === "open" ? true : undefined,
    isClosed: activeTab === "past" ? true : undefined,
  });
  const location = useLocation();
  const theme = useTheme();
  const backLink = location?.state?.backLink;
  const previousIssuerName = location?.state?.previousIssuerName;
  const isWithdrawn = location?.state?.isWithdrawn;
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(
    !!backLink && !!previousIssuerName && !isWithdrawn
  );
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(
    !!backLink && !!previousIssuerName && !!isWithdrawn
  );

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleCloseWithdrawDialog = () => {
    setIsWithdrawDialogOpen(false);
  };

  const handleTabChange = (tab: string) => {
    track({
      name:
        tab === "open"
          ? TrackEventType.AllBallotsOpenEvents
          : TrackEventType.AllBallotsPastEvents,
      brokerId,
      customerId,
    });
    setActiveTab(tab as CommunicationsTabs);
  };

  return (
    <Stack alignItems="center" height="100%" flexGrow={1}>
      <Stack
        sx={{
          maxWidth: "680px",
          width: "100%",
          px: 5,
          pb: 5,
        }}
      >
        <CommunicationsHeader
          activeTab={activeTab}
          communications={communications?.results || []}
          isCommunicationsLoading={isLoading}
          handleTabClick={handleTabChange}
        />
        {!isLoading && communications?.results.length === 0 && (
          <GeneralEmptyState
            description={
              activeTab === "open"
                ? "Any open proxy events will be displayed here"
                : "Any passed proxy events will be displayed here"
            }
          />
        )}
        {isLoading ? (
          <Box>
            <LoadingCommunicationRow />
            <LoadingCommunicationRow />
            <LoadingCommunicationRow />
          </Box>
        ) : (
          <>
            {communications?.results.map((comm, index) => (
              <CommunicationRow
                key={index}
                sx={{
                  mt: 8,
                  [theme.breakpoints.down("sm")]: {
                    mt: 4,
                  },
                }}
                brokerId={brokerId}
                communication={comm}
                communicationIndex={index}
                data-testid="communication-info"
              />
            ))}
          </>
        )}
      </Stack>
      <VoteSuccessDialog
        backLink={backLink}
        isOpen={isSuccessDialogOpen}
        issuerName={previousIssuerName}
        onClose={handleCloseSuccessDialog}
      />
      <VoteWithdrawnDialog
        backLink={backLink}
        isOpen={isWithdrawDialogOpen}
        issuerName={previousIssuerName}
        onClose={handleCloseWithdrawDialog}
      />
    </Stack>
  );
}

import { SayIcon, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";

import { Attachment } from "src/types/Communication";
import { ResourceDialog } from "../ResourceDialog";

interface IResourceRowProps {
  attachment: Attachment;
}

export function ResourceRow({ attachment }: IResourceRowProps) {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { name, url } = attachment;

  const handleClose = (e: React.MouseEvent<Element, MouseEvent>) => {
    setIsDrawerOpen(false);
    // Since our dialog is inside the box with the onClick handler, we need to
    // stop the event from propagating up to the box and triggering the dialog
    // to open again
    e.stopPropagation();
  };

  const handleOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <Box
      data-testid="resource-row"
      sx={{
        display: "flex",
        py: 2,
        color: theme.palette.l4.main,
        alignItems: "center",
        textUnderlineOffset: "4px",
        textDecoration: "none",
        cursor: "pointer",
        "&:last-child": {
          pb: 0,
        },
      }}
      onClick={handleOpen}
    >
      <Box sx={{ height: "24px", mt: "-1px" }}>
        <SayIcon name="DocumentOutline" color={theme.palette.l4.main} />
      </Box>
      <Text
        variant="body2"
        sx={{
          ml: 2,
          color: theme.palette.l4.main,
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {name}
      </Text>
      <ResourceDialog
        isOpen={isDrawerOpen}
        name={name}
        onClose={handleClose}
        url={url}
      />
    </Box>
  );
}

export type ProxyCommunication = {
  type: Omit<CommunicationType, "corporate_action_election">;
  proxyEvent: ProxyEvent;
  voteResults: VoteResult[];
  voteStatus: VoteStatus;
  proposals: Proposal[];
} & Communication;

export type CorporateActionCommunication = {
  type: CommunicationType.CORPORATE_ACTION_ELECTION;
  corporateActionEvent: CorporateActionEvent;
  electionResults: ElectionResult[];
  electionStatus: ElectionStatus;
  offer: Offer;
} & Communication;

export type Communication = {
  id: string;
  type: CommunicationType;
  customer: Customer;
  security: Security;
  positions: Position[];
  documents: Attachment[];
  meetingAttendance: MeetingAttendance | null;
};

export enum CommunicationType {
  PROXY_VOTE = "proxy_vote",
  CORPORATE_ACTION_ELECTION = "corporate_action_election",
  CONSENT_SOLICITATION = "consent_solicitation",
  PROXY_INFORMATIONAL = "proxy_informational",
}

type VoteStatus =
  | "not_viewed"
  | "viewed"
  | "withdrawn"
  | "online"
  | "legal_proxy"
  | "vote_transmission";

type ElectionStatus = "not_viewed" | "viewed" | "online";

type Customer = {
  id: string;
  name: string;
  email: string;
};

type Security = {
  ticker: string;
  cusip: string;
  issuerName: string;
  iconUrl: string;
  issuerType: IssuerType;
  primaryColor: string;
};

export enum IssuerType {
  CORPORATION = "corporation",
  INVESTMENT_TRUST = "investment_trust",
}

export type Position = {
  id: string;
  sharesOwned: string;
  accountIdentifier: string;
  accountType: string | null;
  date: string;
  proxyEligibleShares: string;
};

export type Attachment = {
  id: string;
  url: string;
  name: string;
  type: string;
  description: string;
  contestingPartyUpload: boolean;
};

type MeetingAttendance = {
  created: string;
  document: string | null;
  id: string;
  format: FormatType;
  legalProxy: boolean;
};

type ProxyEvent = {
  meeting: Meeting;
  recordDate: string;
  type:
    | "annual_meeting"
    | "special_meeting"
    | "contested_annual_meeting"
    | "contested_special_meeting";
  voteCutoff: string;
};

type Meeting = {
  contestingParty: string | null;
  formats: Format[];
  meetingDate: string;
  meetingTimezone: string;
  type: "annual" | "special";
};

export type Format = {
  format: FormatType;
  value: string;
  canVote: string;
};

export enum FormatType {
  InPerson = "in_person",
  Virtual = "virtual",
  Telephone = "telephone",
  Private = "private",
}

export type VoteResult = {
  id: string;
  choice: string;
  positionId: string;
  proposalId: string;
  created: string;
};

export type Proposal = {
  id: string;
  title: string;
  details: string;
  voteChoices: string[];
  contestingPartyRecommendation: string;
  recommendation: string;
  groupMaximumVotes: number;
  groupNumber: number;
  proposalNumber: number;
  tooltipText: Node[];
  type:
    | "adjournment"
    | "board_of_directors_nomination"
    | "executive_compensation"
    | "management_proposal"
    | "other"
    | "plan_of_merger"
    | "ratification_of_auditors"
    | "say_on_pay"
    | "shareholder_proposal";
};

type CorporateActionEvent = {
  type: CorporateActionEventType;
  recordDate: string;
  announcementDate: string;
  electionCutoff: string;
  subjectTo302b: boolean;
};

export type CorporateActionEventType =
  | "consent_solicitation"
  | "dividend_option"
  | "dividend_reinvestment_plan"
  | "exchange_offer"
  | "merger_consideration_election"
  | "odd_lot_offer"
  | "buyback"
  | "buyback_auction"
  | "offer_to_purchase"
  | "offer_to_purchase_auction"
  | "preferential_offer"
  | "rights_issue"
  | "rights_offer"
  | "rights_offer_oversubscription"
  | "informational";

type ElectionResult = {
  id: string;
  positionId: string;
  offerId: string;
  choice: "cash" | "stock" | "mixed" | "purchase" | "sell" | "no" | "yes";
  options: Omit<ChoiceDetail, "positionId">;
  created: string;
  accountIdentifier: string | null;
  accountType: string | null;
};

type Offer = {
  id: string;
  title: string;
  detail: string;
  conditions: Condition;
  electionChoices: ElectionChoice[];
};

export type ElectionChoice =
  | "cash"
  | "stock"
  | "mixed"
  | "purchase"
  | "sell"
  | "no"
  | "yes";

export type ChoiceDetail = {
  positionId: string;
  sharesElected?: string;
  shareholderBid?: string;
  rightsElected?: string;
  oversubscriptionElected?: string;
};

export type Condition = {
  contraCusip?: string;
  price?: string;
  options?: ConditionOption[]; // Odd lot offer

  bidIncrement?: string; // Auctions
  maximumBidPrice?: string;
  minimumBidPrice?: string;

  specifiedContraCusip?: string;
  unspecifiedContraCusip?: string;
  offererParty?: string;
};

export type ConditionOption = {
  contraCusip: string;
  maximumShares: string;
  minimumShares: string;
  price: string;
};

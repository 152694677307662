import { useEffect } from "react";

interface IDocumentViewerProps {
  name: string;
  url: string;
}

export const DocumentViewer = ({ name, url }: IDocumentViewerProps) => {
  useEffect(() => {
    if (url) {
      try {
        const adobeDCView = new window.AdobeDC.View({
          clientId: window.config.ADOBE_EMBED,
          divId: "document-viewer",
        });
        adobeDCView.previewFile(
          {
            content: {
              location: {
                url,
              },
            },
            metaData: { fileName: name },
          },
          { showAnnotationTools: false }
        );
      } catch (error) {
        console.error("Failed to load pdf document", error);
      }
    }
  }, [name, url]);

  return <div id="document-viewer" />;
};

import { COLORS, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";

import { StandardInputField } from "src/components/atoms/StandardInputField";
import { FormErrorInfo } from "../ElectionChoice/ElectionChoice";

interface IChoiceLabelProps {
  inputName: string;
  isRights?: boolean;
  isSelected: boolean;
  isOversubscribed?: boolean;
  hasErrors?: boolean;
  onClick?: () => void;
  onError?: (errorInfo: FormErrorInfo) => void;
  preInputText?: string;
  postInputText?: string;
  sharesOwned: number;
}

export function ChoiceLabel({
  hasErrors,
  inputName,
  isRights = false,
  isOversubscribed = false,
  isSelected,
  onClick,
  onError,
  preInputText,
  postInputText,
  sharesOwned,
}: IChoiceLabelProps) {
  const theme = useTheme();
  return (
    <>
      <Text
        variant="body2"
        sx={{
          color: isSelected
            ? hasErrors
              ? COLORS.ERROR_DARK
              : theme.palette.l4.main
            : undefined,
          display: "inline",
          mr: 2,
        }}
      >
        {preInputText}
      </Text>
      <StandardInputField
        autoFocusOnClick={true}
        isInFocus={isSelected}
        name={inputName}
        placeholder={`e.g., ${sharesOwned}`}
        onClick={onClick}
        onError={onError}
        rules={{
          required: {
            value: isSelected,
            message: `Number of ${isRights ? "rights" : "shares"} is required`,
          },
          max: {
            value: sharesOwned,
            message: `Cannot submit more ${
              isRights ? "rights" : "shares"
            } than you own`,
          },
          min: {
            value: 1,
            message: `You must elect at least one ${
              isRights ? "right" : "share"
            }`,
          },
          validate: {
            isWholeNumber: (value) => {
              if (value % 1 !== 0) {
                return `Number of ${
                  isRights ? "rights" : "shares"
                } must be a whole number`;
              }
              return true;
            },
          },
        }}
      />
      <Text
        variant="body2"
        sx={{
          color: isSelected
            ? hasErrors
              ? COLORS.ERROR_DARK
              : theme.palette.l4.main
            : undefined,
          display: "inline",
          ml: 2,
        }}
      >
        {postInputText}
      </Text>
      {isOversubscribed && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StandardInputField
            isInFocus={isSelected}
            name="choiceDetails.0.oversubscriptionElected"
            placeholder={`e.g., ${sharesOwned}`}
            rules={{
              required: {
                value: isSelected,
                message: `Number of ${
                  isRights ? "rights" : "shares"
                } is required`,
              },
              max: {
                value: sharesOwned,
                message: `Cannot submit more ${
                  isRights ? "rights" : "shares"
                } than you own`,
              },
              min: {
                value: 1,
                message: `You must elect at least one ${
                  isRights ? "right" : "share"
                }`,
              },
              validate: {
                isWholeNumber: (value) => {
                  if (value % 1 !== 0) {
                    return `Number of ${
                      isRights ? "rights" : "shares"
                    } must be a whole number`;
                  }
                  return true;
                },
              },
            }}
          />
          <Text
            variant="body2"
            sx={{
              color: isSelected
                ? hasErrors
                  ? COLORS.ERROR_DARK
                  : theme.palette.l4.main
                : undefined,
              ml: 2,
            }}
          >
            oversubscribed rights elected.
          </Text>
        </Box>
      )}
    </>
  );
}
